<template>
    <div id="WordPreview" class="h-100">
    <Header :reloadfunc="onReload"/>    
    <CommonAlert1 ref="CommonAlert1" :title="title"  :btnnameup="btnNameUp" :content="content"/>

    <body class=" bg-white" id="wordPreviewBody">
    <div v-if="isTeaching" class="" style="position: absolute; background-color: rgba(0, 0, 0, .5);z-index: 1050; width: 100%; height:100vh;" @click="onTeaching">  </div>
    <!-- header -->
    <nav class="navbar bg-white fixed-top nicole-noshadow" style="z-index: 1080; background-color:white;">
      <div class="container">
        <a class="navbar-brand font-weight-bolder me-0 col-4" rel="tooltip" data-placement="bottom" @click="onBack">
          <img class="avatar avatar-sm" src="assets/img/icon-back-black.png">
        </a>            
        <a class="navbar-brand font-weight-bold me-0 col-4 text-center" rel="tooltip" data-placement="bottom">
          單字預習
        </a>             
        <a class="navbar-brand font-weight-bolder me-0 text-right col-4" >
            <div v-if="isTeaching" class="popover fade show bs-popover-bottom greenpop" role="tooltip" data-popper-placement="bottom">
                <div class="popover-arrow green-arrow"></div>
                <h3 class="popover-header"></h3>
                <div class="popover-body text-left py-3">
                    <img src="assets/img/ic_nav_playall.png" class="avatar avatar-xs me-2">自動播放全部單字
                    <hr class="text-gray">
                    <img src="assets/img/ic_nav_playwithsentence.png" class="avatar avatar-xs me-2">自動播放全部單字與例句
                    <hr class="text-gray">
                    <img src="assets/img/ic_nav_download.png" class="avatar avatar-xs me-2">下載離線收聽音檔
                </div>
            </div>
            <img class="avatar avatar-sm rounded-0" :src=" (isVisitor || isMember) ? 'assets/img/ic_nav_playall_gray.png': isPlay && isPlayWord ?'assets/img/ic_nav_playall_stop.png':'assets/img/ic_nav_playall.png'" @click="onPlayWord">
            <img class="avatar avatar-sm rounded-0 ms-1" :src=" (isVisitor || isMember) ? 'assets/img/ic_nav_playwithsentence_no.png': isPlay && isPlayAll ?'assets/img/ic_nav_playwithsentence_stop.png':'assets/img/ic_nav_playwithsentence.png'" @click="onPlayAll">
            <img class="avatar avatar-sm rounded-0 ms-1" :src=" (isVisitor || isMember) ? 'assets/img/ic_nav_download_no.png': 'assets/img/ic_nav_download.png'" @click="onDownload">
        </a> 
      </div>
    </nav>
<div class="nicolespace"></div>
<!-- End header -->
    <div class="">   
        <!-- 單字例句 Start --> 
        <div :class="((index - 1)%2 == 0)?'py-2':'py-2 bg-soft-light'" v-for="index in wordMax" :key="index">
        <div class="container">
            <!-- 單字例句 Start --> 
            <div class="d-flex">
                <div class="titlebox text-dark text-simbold text-mm mb-1">{{index}}.</div>
                <div class="contentbox">
                    <div class="d-flex align-content-center justify-content-between">
                    <!-- eslint-disable vue/no-v-html -->
                    <div class="col-9 text-dark text-bold text-18 mb-1 text-break" v-html="getName(index)"></div>
                    <!--eslint-enable-->
                    <div class="col-3 text-right">
                        <img class="avatar avatar-sm rounded-0 " :src="getWordImgSrc(index)" @click="onPlayWordVoic(index)">    
                        <img class="avatar avatar-sm rounded-0 ms-3" :src="$GlobalData.uid == '' ? 'assets/img/ic_nav_unsave_no.png': getCollect(index)? 'assets/img/ic_saved.png':'assets/img/ic_unsave.png'" @click="onCollect(index)">    

                    </div>
                    </div>
                    <!-- eslint-disable vue/no-v-html -->
                    <h5 class="text-gray text-s" v-html="getPhonetic(index)"></h5>
                    <h5 class="text-dark text-s" v-for="(speech,speechIndex) of getSpeech(index)" :key="speechIndex" v-html="speech.code+' '+speech.name"></h5>
                    <!--eslint-enable-->
                </div>
            </div> <hr>
            <!-- 單字例句 End -->    
            <!-- 例句 Start --> 
            <div class="d-flex" v-for="(sentence,sentenceIndex) of getSentence(index)" :key="sentenceIndex">
                <div class="titlebox mb-1"><img :src="geSentenceImgSrc(index, sentenceIndex)" class="avatar avatar-22 rounded-0" @click="onPlaySentenceVoic(index, sentenceIndex)"></div>
                <div class="contentbox">
                    <!-- eslint-disable vue/no-v-html -->
                    <div class="text-dark text-simbold text-mm mb-1" v-html="sentence.name"></div>
                    <h5 class=" text-s text-dark" v-html="sentence.description"></h5>
                    <!--eslint-enable-->
                </div>
            </div>
            <!-- 例句 End -->   
        </div>            
        </div>   
        <!-- 單字例句 End -->       
        
        
      <div class="py-4" v-if="(!isVisitor && !isMember)">
        <div class="text-right">
        <button type="button" class="btn btn-lg  btn-success side-btn rounded-start1  pe-0 ps-3 text-center" @click="onTeacherExplain"><h5 class="text-white text-smm mb-0 d-flex text-simbold">老師講解 <i class="far fa-chevron-right text-white ms-2 text-smm"></i></h5></button>
        </div> 
      </div> 
    </div>
    <div>
    </div>
<!-- footer -->
<div class="nicolespace"></div>
<div class="nicolespace"></div>
<div class="nicolespace"></div>

<div class="threeday fixed-bottom mb-3 mx-auto" v-if="!isPlayAll && !isPlayWord && (isVisitor || isMember)">
    <button type="button" class="btn btn-lg  btn-success btn-yellow-shadow rounded-pill w-100  px-2 text-nowrap " @click="onUpgrade">
        <h5 class="text-white text-card-title  mb-0 d-flex align-items-center justify-content-center text-simbold ">{{isVisitor ? '想閱讀更多內容，立即註冊會員免費體驗三日' :'想閱讀更多內容，立即升級為VIP會員' }}
            <i class="fal fa-chevron-right text-white ms-1 text-card-title "></i>
        </h5>
    </button> 
</div>

<nav class="navbar  navbar-light bg-white z-index-3  fixed-bottom py-3 py-md-3 rounded-top1" v-show="isPlayWord || isPlayAll">
    <div class="threeday nicole-center" v-if="isVisitor || isMember">
      <button type="button" class="btn btn-lg  btn-success btn-yellow-shadow rounded-pill w-100  px-2 text-nowrap" @click="onUpgrade">
          <h5 class="text-white text-card-title  mb-0 d-flex align-items-center justify-content-center text-simbold">{{isVisitor ? '想閱讀更多內容，立即註冊會員免費體驗三日' :'想閱讀更多內容，立即升級為VIP會員' }}
              <i class="far fa-chevron-right text-white ms-1 text-card-title "></i>
          </h5>
     </button>
    </div>

    <div class="w-100 px-4 px-md-5 px-xl-6 pb-3 pt-md-1">
        <div class="d-flex align-items-center justify-content-between">
            <!-- <div class="text-light  text-s ">{{isPlayWord? currWordNum : currAllNum}}</div> -->
            <div class="text-light  text-s "></div>
            <div class="col-9 col-sm-10 col-md-11">   
                <vue-slider v-model="value" :tooltip="'none'"/>
            </div>
            <!-- <div class="text-light  text-s ">{{isPlayWord? totalWordNum : totalAllNum}}</div> -->
            <div class="text-light  text-s ">{{isPlayWord? currWordNum + "/" +totalWordNum : currAllNum + "/" +totalAllNum}}</div>
        </div>
    </div> 

    <div class="col-12 col-md-7 col-xl-6 mx-auto px-3">
    <div class="d-flex align-items-center justify-content-between">
        <div class="w-20 text-center">
          <img src="assets/img/ic_player_replay.png" class="avatar avatar-sm rounded-0" v-show="false"> 
        </div>       
        <div class="w-20 text-center">
          <img src="assets/img/ic_rewind.png" class="avatar avatar-30 rounded-0" v-show="false"> 
        </div>        
        <div class="w-20 text-center">
          <img :src="isPlay? 'assets/img/ic_player_pause.png' : 'assets/img/ic_player_play.png'" class="avatar avatar-l rounded-0" @click="onPlay()"> 
        </div>       
        <div class="w-20 text-center">
          <img src="assets/img/ic_play_forward.png" class="avatar avatar-30 rounded-0" v-show="false"> 
        </div>        
        <div class="w-20 text-center">
          <img src="assets/img/ic_player_more.png" class="avatar avatar-sm rounded-0" v-show="false"> 
        </div>
    </div>
    </div>
</nav>
<!-- Modal -->
    <!-- Voic 播放器 -->
    <video-player  class="video-player-box"
                ref="voicPlayer"
                :options="voicPlayerOptions"
                :playsinline="true"
                @play="onPlayerPlay($event, 'voic')"
                @pause="onPlayerPause($event, 'voic')"
                @ended="onPlayerEnded($event, 'voic')"
                @canplay="onPlayerCanplay($event, 'voic')"
                @ready="playerReadied($event, 'voic')"
                v-show="false"
                >
  </video-player>
    <!-- Word 播放器 -->
    <video-player  class="video-player-box"
                ref="wordPlayer"
                :options="wordPlayerOptions"
                :playsinline="true"
                @play="onPlayerPlay($event, 'word')"
                @pause="onPlayerPause($event, 'word')"
                @ended="onPlayerEnded($event, 'word')"
                @timeupdate="onPlayerTimeupdate($event, 'word')"
                @canplay="onPlayerCanplay($event, 'word')"
                @ready="playerReadied($event, 'word')"
                @waiting="onPlayerWaiting($event, 'word')"
                @playing="onPlayerPlaying($event, 'word')"
                @loadeddata="onPlayerLoadeddata($event, 'word')"
                v-show="false"
                >
  </video-player>
    <!-- Sentence 播放器 -->
    <video-player  class="video-player-box"
                ref="allPlayer"
                :options="allPlayerOptions"
                :playsinline="true"
                @play="onPlayerPlay($event, 'all')"
                @pause="onPlayerPause($event, 'all')"
                @ended="onPlayerEnded($event, 'all')"
                @timeupdate="onPlayerTimeupdate($event, 'all')"
                @canplay="onPlayerCanplay($event, 'all')"
                @ready="playerReadied($event, 'all')"
                v-show="false"
                >
  </video-player>

</body>
    </div>
</template>



<script>
import Header from '@/components/Header.vue'
import { oltConsole , oAC } from "@/lib/oltModule/initClient";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import CommonAlert1 from '@/components/CommonAlert1.vue'
import { config } from "@/include/config";

export default {
    name: 'WordReexercise',
    components: {
        Header,
        VueSlider,
        CommonAlert1,
    },
    data () {
    return { 
        apiName: 'page_word_reexercise',
        voicPlayerOptions: {
            // videojs options
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [],
            poster: "/static/images/author.jpg",
            },
        wordPlayerOptions: {
            // videojs options
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [],
            poster: "/static/images/author.jpg",
            },
        allPlayerOptions: {
            // videojs options
            muted: false,
            language: 'en',
            playbackRates: [0.7, 1.0, 1.5, 2.0],
            sources: [],
            poster: "/static/images/author.jpg",
            },
        isPlay: false,
        isPlayVoic: false,
        isPlayWord: false,
        isPlayWordPlaying: false,
        isPlayWordEnd: false,
        isPlayAll: false,
        isPlayAllPlaying: false,
        isPlayAllEnd: false,
        currAllNum: 0,
        totalAllNum: 0,
        currWordNum: 0,
        totalWordNum: 0,
        isVisitor: true,
        isMember: false,
        isProduct: false,
        history_user_product:[],
        lesson_word:[],
        lsid: "",
        wordMax: 0,
        params: {},
        backUrl: "",
        backMode: "",
        ulid: "",
        value: 0,
        playType: "",
        playIndex: -1,
        playSentenceIndex: -1,
        wordAudioList:[],
        allAudioList:[],
        form:{},
        title:"",
        content:"",
        btnNameUp:"我知道了",
        native:"",
        isTeaching: false,
        config:"",
        }
    },
    methods: {
        // listen event
        onPlayerPlay(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerPlay ==> ${type}`,this.logCallback);
            if(!this.isPlayVoic){
                this.isPlay = true;
                switch(type){
                    case "word":
                        this.isPlayWordPlaying = true;
                    break;
                    case "all":
                        this.isPlayAllPlaying = true;
                    break;
                }
            }
            console.log(`onPlayerPlay ==> ${player}`);
            //this.$JsBridge.callHandler('log',`onPlayerPlay ==> ${player}`,this.logCallback);
        },
        onPlayerPause(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerPause ==> ${type}`,this.logCallback);
            switch(type){
                case "word":
                    this.isPlayWordPlaying = false;
                break;
                case "all":
                    this.isPlayAllPlaying = false;
                break;
            }
            console.log(`onPlayerPause ==> ${player}`);
            //this.$JsBridge.callHandler('log',`onPlayerPause ==> ${player}`,this.logCallback);
        },
        onPlayerEnded(player, type) {
            console.log(`onPlayerEnded ==> ${player}`);
            //this.$JsBridge.callHandler('log',`onPlayerEnded ==> ${player}`,this.logCallback);

            switch(type){
                case "voic":
                    switch(this.playType){
                        case "word":
                            if(this.playIndex >= 0 && this.lesson_word && this.lesson_word.length > this.playIndex){
                                this.lesson_word[this.playIndex].isPlay = "N";
                            }
                            break;
                        case "sentence":
                            if(this.playIndex >= 0 && this.playSentenceIndex >= 0 && this.lesson_word && this.lesson_word.length > this.playIndex && this.lesson_word[this.playIndex].lesson_word_sentence && this.lesson_word[this.playIndex].lesson_word_sentence.length > this.playSentenceIndex){
                                this.lesson_word[this.playIndex].lesson_word_sentence[this.playSentenceIndex].isPlay = "N";
                            }
                            break;
                    }
                    this.refreshLessonWord();
                    this.playType = "";
                    this.playIndex = -1;
                    this.playSentenceIndex = -1;
                    this.isPlayVoic = false;
                break;
                case "word":
                    this.isPlayWordEnd = true;
                    if(this.currWordNum == this.totalWordNum){
                        this.currWordNum = 1;
                        this.isPlay = false;
                        this.value = 0;
                        this.completeTask();
                    }else{
                        this.currWordNum += 1;
                        this.loadWord();
                    }
                break;
                case "all":
                    this.isPlayAllEnd = true;
                    if(this.currAllNum == this.totalAllNum){
                        this.currAllNum = 1;
                        this.isPlay = false;
                        this.value = 0;
                        this.completeTask();
                    }else{
                        this.currAllNum += 1;
                        this.loadAll();
                    }
                break;
            }
        },
        onPlayerTimeupdate(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerTimeupdate ==> ${type}`,this.logCallback);
            //console.log(`onPlayerTimeupdate ==> ${player.remainingTime()}`);
            //console.log(player);
            //console.log(`duration ==> ${player.duration()}`);
            switch(type){
                case "word":
                    this.value =  (1 - (player.remainingTime() / player.duration())) * 100;
                break;
                case "all":
                    this.value =  (1 - (player.remainingTime() / player.duration())) * 100;
                break;
            }
        },
        onPlayerCanplay(player, type) {
            console.log(`onPlayerCanplay ==> ${type}`);
            this.$JsBridge.callHandler('log',`onPlayerCanplay ==> ${type}`,this.logCallback);
            if (this.$JsBridge.isAndroid()) {
                switch(type){
                    case "voic":
                        this.isPlay = false;
                        this.value = 0;
                        switch(this.playType){
                            case "word":
                                this.updateWordIsPlay(this.playIndex);
                                break;
                            case "sentence":
                                this.updateSentenceIsPlay(this.playIndex, this.playSentenceIndex);
                                break;
                        }
                        this.refreshLessonWord();
                        if(this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                            if(this.isPlayWordPlaying){
                                this.isPlay = false;
                                this.$refs.wordPlayer.player.pause();
                            }
                        }
                        if(this.$refs.allPlayer && this.$refs.allPlayer.player){
                            if(this.isPlayAllPlaying){
                                this.isPlay = false;
                                this.$refs.allPlayer.player.pause();
                            }
                        }
                        if(this.$refs.voicPlayer && this.$refs.voicPlayer.player){
                            this.$refs.voicPlayer.player.play();
                        }
                        console.log(`isPlay : ${this.isPlay}`);
                    break;
                    case "word":
                        if(this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                            this.$refs.wordPlayer.player.play();
                        }
                    break;
                    case "all":
                        if(this.$refs.allPlayer && this.$refs.allPlayer.player){
                            this.$refs.allPlayer.player.play();
                        }
                    break;
                }
            }
        },
        // player is ready
        playerReadied(player, type) {
            console.log('the player is readied', player);
            this.$JsBridge.callHandler('log',`playerReadied ==> ${type}`,this.logCallback);
            if (this.$JsBridge.isiOS()) {
                switch(type){
                    case "voic":
                        this.isPlay = false;
                        this.value = 0;
                        switch(this.playType){
                            case "word":
                                this.updateWordIsPlay(this.playIndex);
                                break;
                            case "sentence":
                                this.updateSentenceIsPlay(this.playIndex, this.playSentenceIndex);
                                break;
                        }
                        this.refreshLessonWord();
                        if(this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                            if(this.isPlayWordPlaying){
                                this.isPlay = false;
                                this.$refs.wordPlayer.player.pause();
                            }
                        }
                        if(this.$refs.allPlayer && this.$refs.allPlayer.player){
                            if(this.isPlayAllPlaying){
                                this.isPlay = false;
                                this.$refs.allPlayer.player.pause();
                            }
                        }
                        if(this.$refs.voicPlayer && this.$refs.voicPlayer.player){
                            if(this.$JsBridge.isiOS){
                                setTimeout(()=>{
                                    if(this.$refs.voicPlayer && this.$refs.voicPlayer.player){
                                        this.$refs.voicPlayer.player.play();
                                    }
                                }, 300);   
                            }else{
                                this.$refs.voicPlayer.player.play();
                            }
                        }
                        console.log(`isPlay : ${this.isPlay}`);
                    break;
                    case "word":
                        if(this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                            if(this.$JsBridge.isiOS){
                                setTimeout(this.onPlay, 300);   
                            }else{
                                this.$refs.wordPlayer.player.play();
                            }
                        }
                    break;
                    case "all":
                        if(this.$refs.allPlayer && this.$refs.allPlayer.player){
                            if(this.$JsBridge.isiOS){
                                setTimeout(this.onPlay, 300);   
                            }else{
                                this.$refs.allPlayer.player.play();
                            }
                        }
                    break;
                }
            }
        },
        onPlayerWaiting(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerWaiting ==> ${type}`,this.logCallback);
        },
        onPlayerPlaying(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerPlaying ==> ${type}`,this.logCallback);
        },
        onPlayerLoadeddata(player, type) {
            this.$JsBridge.callHandler('log',`onPlayerLoadeddata ==> ${type}`,this.logCallback);
        },
        onPlay(){
            if(this.isPlayWord){
                if(!this.isPlayWordEnd){
                    if(this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                        if(this.isPlayWordPlaying){
                            this.isPlay = false;
                            this.$refs.wordPlayer.player.pause();
                        }else{
                            this.isPlay = true;
                            this.$refs.wordPlayer.player.play();
                        }
                    }
                }else{
                    this.isPlay = true;
                    this.loadWord();
                }
            }else if(this.isPlayAll){
                
                if(!this.isPlayAllEnd){
                    if(this.$refs.allPlayer && this.$refs.allPlayer.player){
                        if(this.isPlayAllPlaying){
                            this.isPlay = false;
                            this.$refs.allPlayer.player.pause();
                        }else{
                            this.isPlay = true;
                            this.$refs.allPlayer.player.play();
                        }
                    }
                }else{
                    this.isPlay = true;
                    this.loadAll();
                }
            }
        },
        onPlayWord(){
            if(this.$GlobalData.uid == undefined || this.$GlobalData.uid == ""){
                return;
            }
            if(this.isVisitor || this.isMember){
                return;
            }
            if(this.isPlayAllPlaying && this.$refs.allPlaye && this.$refs.allPlaye.player){
                this.$refs.allPlaye.player.pause();
            }
            if(!this.isPlayWord){
                this.isPlay = true;
                this.isPlayWord = true;
                this.currWordNum = 1;
                this.totalWordNum = this.wordAudioList.length;
                this.loadWord();
            }else{
                if(this.isPlay){
                    this.isPlay = false;
                    if(this.isPlayWordPlaying){
                        if(this.$refs.wordPlaye && this.$refs.wordPlaye.player){
                            this.$refs.wordPlaye.player.pause();
                        }
                    }else{
                        if(this.$refs.wordPlaye && this.$refs.wordPlaye.player){
                            this.$refs.wordPlaye.player.play();
                        }
                    }
                }else{
                    this.isPlay = true;
                    this.onPlay();
                }
            }
            this.isPlayAll = false;
            this.isPlayVoic = false;
            this.isPlayAllPlaying = false;
            this.resetLessonWordIsPlay();
            this.resetLessonWordSentenceIsPlay();

        },
        loadWord(){
            let num = this.currWordNum - 1;
            if(num >= 0 && this.wordAudioList && this.wordAudioList.length > num){
                let url = this.wordAudioList[num];
                console.log(`loadWord num ==> ${num}, url ==> ${url}`);
                if(url != ""){
                    this.apiName = "video_get_token";
                    oAC.getAllRecord(oAC.getApiParam(this), {src:url}).then(
                    res => 
                    { 
                        this.value = 0;
                        if(res.get_video_token && res.get_video_token.data && res.get_video_token.data.token && res.get_video_token.data.token != undefined){
                            var json = {
                                type: "application/x-mpegURL",
                                src : `${url}?token=${res.get_video_token.data.token}`,
                            };
                            this.wordPlayerOptions.sources.length = 0;
                            if(this.isPlayWord && this.isPlay && !this.isPlayVoic){
                                this.isPlayWordEnd = false;
                                if(this.$JsBridge.isiOS){
                                    this.$refs.wordPlayer.muted = true;
                                }
                                this.$JsBridge.callHandler('log',`loadWord ==> ${JSON.stringify(json)}`,this.logCallback);
                                this.wordPlayerOptions.sources.push(json);
                            }
                        }
                    }).catch((err)=> {
                        this.value = 0;
                        oltConsole.log(err);
                    });
                }
            }
        },
        onPlayAll(){
            if(this.$GlobalData.uid == undefined || this.$GlobalData.uid == ""){
                return;
            }
            if(this.isVisitor || this.isMember){
                return;
            }
            if(this.isPlayWordPlaying && this.$refs.wordPlayer && this.$refs.wordPlayer.player){
                this.$refs.wordPlayer.player.pause();
            }
            this.isPlayAlldEnd = false;
            if(!this.isPlayAll){
                this.isPlay = true;
                this.isPlayAll = true;
                this.isPlayWord = false;
                this.currAllNum = 1;
                this.totalAllNum = this.allAudioList.length;
                this.loadAll();
            }else{
                if(this.isPlay){
                    this.isPlay = false;
                    if(this.isPlayAllPlaying){
                        if(this.$refs.allPlaye && this.$refs.allPlaye.player){
                            this.$refs.allPlaye.player.pause();
                        }
                    }else{
                        if(this.$refs.allPlaye && this.$refs.allPlaye.player){
                            this.$refs.allPlaye.player.play();
                        }
                    }
                }else{
                    this.isPlay = true;
                    this.onPlay();
                }
            }
            this.isPlayWord = false;
            this.isPlayVoic = false;
            this.isPlayWordPlaying = false;
            this.resetLessonWordIsPlay();
            this.resetLessonWordSentenceIsPlay();
        },
        loadAll(){
            let num = this.currAllNum - 1;
            if(num >= 0 && this.allAudioList && this.allAudioList.length > num){
                let url = this.allAudioList[num];
                console.log(`loadAll num ==> ${num}, url ==> ${url}`);
                if(url != ""){
                    this.apiName = "video_get_token";
                    oAC.getAllRecord(oAC.getApiParam(this), {src:url}).then(
                    res => 
                    { 
                        this.value = 0;
                        if(res.get_video_token && res.get_video_token.data && res.get_video_token.data.token && res.get_video_token.data.token != undefined){
                            var json = {
                                type: "application/x-mpegURL",
                                src : `${url}?token=${res.get_video_token.data.token}`,
                            };
                            this.allPlayerOptions.sources.length = 0;
                            if(this.isPlayAll && this.isPlay && !this.isPlayVoic){
                                this.isPlayAllEnd = false;
                                if(this.$JsBridge.isiOS){
                                    this.$refs.allPlayer.muted = true;
                                }
                                this.allPlayerOptions.sources.push(json);
                            }
                        }
                    }).catch((err)=> {
                        this.value = 0;
                        oltConsole.log(err);
                    });
                }
            }
        },
        getName(index){
            var name = "";
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                name = this.lesson_word[num].name;
            }
            return name;
        },
        getWordImgSrc(index){
            var src = "";
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                if(this.lesson_word[num].isPlay == "Y"){
                    src = "assets/img/ic_loudspeaker_playing.png";
                }else if(this.lesson_word[num].isPlay == "N"){
                    src = "assets/img/ic_loudspeaker.png";
                }
            }
            return src;
        },
        geSentenceImgSrc(index, sentenceIndex){
            var src = "";
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num && this.lesson_word[num].lesson_word_sentence && this.lesson_word[num].lesson_word_sentence.length > sentenceIndex){                
                if(this.lesson_word[num].lesson_word_sentence[sentenceIndex].isPlay == "Y"){
                    src = "assets/img/ic_loudspeaker_playing.png";
                }else if(this.lesson_word[num].lesson_word_sentence[sentenceIndex].isPlay == "N"){
                    src = "assets/img/ic_loudspeaker_green.png";
                }
            }
            return src;
        },
        logCallback(){},
        onPlayWordVoic(index){
            //this.$JsBridge.callHandler('log','onPlayWordVoic',this.logCallback);
            var url = "";
            var num = index - 1;
            this.playType = "word";
            this.playIndex = num;
            if(this.lesson_word && this.lesson_word.length > num){
                if(this.lesson_word[num].isPlay == "Y"){
                    return;
                }
                url = this.lesson_word[num].audio_url_m3u8;
            }
            if(url != ""){
                //this.$JsBridge.callHandler('log',`url ==> ${url}`,this.logCallback);
                this.apiName = "video_get_token";
                oAC.getAllRecord(oAC.getApiParam(this), {src:url}).then(
                res => 
                { 
                if(res.get_video_token && res.get_video_token.data && res.get_video_token.data.token && res.get_video_token.data.token != undefined){
                    var json = {
                        type: "application/x-mpegURL",
                        src : `${url}?token=${res.get_video_token.data.token}`,
                    };
                    //this.$JsBridge.callHandler('log',`json ==> ${JSON.stringify(json)}`,this.logCallback);
                    this.isPlayVoic = true;
                    this.voicPlayerOptions.sources.length = 0;
                    this.voicPlayerOptions.sources.push(json);
                    if(this.$JsBridge.isiOS){
                        this.$refs.voicPlayer.muted = true;
                    }
                }
                console.log(res);
                }).catch((err)=> {
                    oltConsole.log(err);
                });
            }
        },
        onPlaySentenceVoic(index, sentenceIndex){
            var url = "";
            var num = index - 1;
            this.playType = "sentence";
            this.playIndex = num;
            this.playSentenceIndex = sentenceIndex;
            console.log(`num : ${num}, sentenceIndex : ${sentenceIndex}`);
            if(this.lesson_word && this.lesson_word.length > num && this.lesson_word[num].lesson_word_sentence && this.lesson_word[num].lesson_word_sentence.length > sentenceIndex){                
                if(this.lesson_word[num].lesson_word_sentence[sentenceIndex] == "Y"){
                    return;
                }
                url = this.lesson_word[num].lesson_word_sentence[sentenceIndex].audio_url_m3u8;
                if(url != ""){
                    this.updateSentenceIsPlay(num, sentenceIndex);
                }
                this.refreshLessonWord();
            }
            if(url != ""){
                this.apiName = "video_get_token";
                oAC.getAllRecord(oAC.getApiParam(this), {src:url}).then(
                res => 
                { 
                if(res.get_video_token && res.get_video_token.data && res.get_video_token.data.token && res.get_video_token.data.token != undefined){
                    var json = {
                        type: "application/x-mpegURL",
                        src : `${url}?token=${res.get_video_token.data.token}`,
                    };
                    this.isPlayVoic = true;
                    this.voicPlayerOptions.sources.length = 0;
                    this.voicPlayerOptions.sources.push(json);
                    if(this.$JsBridge.isiOS){
                        this.$refs.voicPlayer.muted = true;
                    }
                }
                console.log(res);
                }).catch((err)=> {
                    oltConsole.log(err);
                });
            }
        },
        onCollect(index){
            if(this.$GlobalData.uid == undefined || this.$GlobalData.uid == ""){
                return;
            }
            var lwid = "";
            var isCollect = "";
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                lwid = this.lesson_word[num].lwid;
                isCollect = this.lesson_word[num].is_collect_lesson_word;
            }
            if(lwid != "" && isCollect != ""){
                this.form = {};
                this.form["uid"] = this.$GlobalData.uid;
                this.form["lwid"] = lwid;
                if(isCollect == "N"){
                    this.apiName = 'page_word_preview_collect_word';
                }else if(isCollect == "Y"){
                    this.apiName = 'page_word_preview_delete_collect_word';
                }
                oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
                    if(res.success && res.success == "Y"){
                        if(isCollect == "N"){
                            this.lesson_word[num].is_collect_lesson_word = "Y";
                        }else if(isCollect == "Y"){
                            this.lesson_word[num].is_collect_lesson_word = "N";
                        }
                        this.refreshLessonWord();
                    }
                }).catch((err)=> {
                    oltConsole.log(err);
                });
            }
        },
        getPhonetic(index){
            var phonetic = "";
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                phonetic = this.lesson_word[num].phonetic;
            }
            return phonetic;
        },
        getSpeech(index){
            var lesson_word_speech_rt = [];
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                lesson_word_speech_rt = this.lesson_word[num].lesson_word_speech_rt;
            }
            return lesson_word_speech_rt;
        },
        getCollect(index){
            var collect = false;
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                if(this.lesson_word[num].is_collect_lesson_word == "Y"){
                    collect = true;
                }
            }
            return collect;
        },
        getSentence(index){
            var lesson_word_sentence = [];
            var num = index - 1;
            if(this.lesson_word && this.lesson_word.length > num){
                lesson_word_sentence = this.lesson_word[num].lesson_word_sentence;
            }
            return lesson_word_sentence;
        },
        openLoginCallback(){},
        onUpgrade(){
            if(this.isVisitor){
                var back = `WordPreview?lsid=${this.lsid}&backMode=${this.backMode}&backUrl=${btoa(this.backUrl)}`;
                this.$JsBridge.callHandler('openVueLoginView',JSON.stringify({backUrl:back, type:"",skipBack:back}), this.openLoginCallback);
            }else{
                var back = `WordPreview?lsid=${this.lsid}&backMode=${this.backMode}&backUrl=${btoa(this.backUrl)}`;
                console.log(`back ==> ${back}`);
                this.$router.push(
                {
                    name : 'UpgradeVip',
                    params : {
                        backMode: this.backMode,
                        backUrl: back,
                    }
                });
            }
        },
        updateWordIsPlay(index){
            this.resetLessonWordSentenceIsPlay();
            if(this.lesson_word && this.lesson_word.length > index){
                for(var i = 0; i < this.lesson_word.length; i++){
                    if(i == index){
                        this.lesson_word[i].isPlay = "Y";
                    }else{
                        this.lesson_word[i].isPlay = "N";
                    }
                }
            }
        },
        updateSentenceIsPlay(index, sentenceIndex){
            this.resetLessonWordIsPlay();
            if(this.lesson_word && this.lesson_word.length > index && this.lesson_word[index].lesson_word_sentence &&  this.lesson_word[index].lesson_word_sentence.length > sentenceIndex){
                for(var i = 0; i < this.lesson_word.length; i++){
                    for(var k = 0; k < this.lesson_word[i].lesson_word_sentence.length; k++){
                        if(i == index && k == sentenceIndex){
                            this.lesson_word[i].lesson_word_sentence[k].isPlay = "Y";
                        }else{
                            this.lesson_word[i].lesson_word_sentence[k].isPlay = "N";
                        }
                    }
                }
            }
        },
        resetLessonWordIsPlay(){
            if(this.lesson_word && this.lesson_word.length > 0){
                for(var i = 0; i < this.lesson_word.length; i++){
                    this.lesson_word[i].isPlay = "N";
                }
            }
        },
        resetLessonWordSentenceIsPlay(){
            if(this.lesson_word && this.lesson_word.length > 0){
                for(var i = 0; i < this.lesson_word.length; i++){
                    if(this.lesson_word[i].lesson_word_sentence && this.lesson_word[i].lesson_word_sentence.length > 0){
                        for(var k = 0; k < this.lesson_word[i].lesson_word_sentence.length; k++){
                            this.lesson_word[i].lesson_word_sentence[k].isPlay = "N";
                        }
                    }
                }
            }
        },
        refreshLessonWord(){
            let temp = this.lesson_word;
            this.lesson_word = [];
            this.lesson_word = temp;
        },
        onDownloadCancel(){},
        sentenceCountCallback(count){
            if(count != "" && parseInt(count) < 3){
                this.download('sentence');
            }else{
                this.title = '無法下載音檔';
                this.content = '已經下載滿三則囉!請於「我的」裡面的「離線收聽」刪除現有紀錄才能下載喔。';
                this.$refs.CommonAlert1.onShow();
            }
        },
        sentenceFileIsExistCallback(res){
            if(res != "" && res == "Y"){
                this.title = '無法下載音檔';
                this.content = '已經下載過這則囉!可於「我的」裡面的「離線收聽」查看現有紀錄。';
                this.$refs.CommonAlert1.onShow();
            }else{
                // 檢查是否已下載三則
                this.$JsBridge.callHandler('checkPackageCount', 'sentence', this.sentenceCountCallback);
            }
        },
        onDownloadAll(){
            // 檢查是否已下載
            this.$JsBridge.callHandler('checkFileIsExist', JSON.stringify({lsid:this.lsid, type:'sentence'}) , this.sentenceFileIsExistCallback);
        },
        previewCountCallback(count){
            if(count != "" && parseInt(count) < 3){
                this.download('preview');
            }else{
                this.title = '無法下載音檔';
                this.content = '已經下載滿三則囉!請於「我的」裡面的「離線收聽」刪除現有紀錄才能下載喔。';
                this.$refs.CommonAlert1.onShow();
            }
        },
        previewFileIsExistCallback(res){
            if(res != "" && res == "Y"){
                this.title = '無法下載音檔';
                this.content = '已經下載過這則囉!可於「我的」裡面的「離線收聽」查看現有紀錄。';
                this.$refs.CommonAlert1.onShow();
            }else{
                // 檢查是否已下載三則
                this.$JsBridge.callHandler('checkPackageCount', 'preview', this.previewCountCallback);
            }
        },
        onDownloadWord(){
            this.$JsBridge.callHandler('checkFileIsExist', JSON.stringify({lsid:this.lsid, type:'preview'}) , this.previewFileIsExistCallback);            
        },
        download(type){   
            console.log(`download type : ${type}`);         
            this.apiName = 'page_word_preview_download';
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid, lsid:this.lsid, type:type}).then((res)=> {
                console.log(`download res : ${JSON.stringify(res)}`);   
                if(res.title && res.title != "" && res.title != undefined && res.message && res.message != "" && res.message != undefined){
                    this.title = res.title;
                    this.content = res.message;
                    this.$refs.CommonAlert1.onShow();
                    console.log(`title : ${this.title}, content : ${this.content}`);   
                }else if(res.data && res.data != undefined){
                    this.$JsBridge.callHandler('downloadPackage', JSON.stringify(res.data), this.downloadCallback);
                }
            }).catch((err)=> {
                oltConsole.log(err);
            });
        },
        downloadCallback(data){},
        onDownload(){
            if(this.$GlobalData.uid == undefined || this.$GlobalData.uid == ""){
                return;
            }
            if(this.isVisitor || this.isMember){
                return;
            }
            window["onDownloadAll"] = this.onDownloadAll;
            window["onDownloadWord"] = this.onDownloadWord;
            let json = {
                cancelName : '取消',
                btnGroup : [
                    {
                        callback: "onDownloadAll",
                        name: "單字和例句音檔",
                    },
                    {
                        callback: "onDownloadWord",
                        name: "單字音檔",
                    },
                ]
            };
            this.$JsBridge.callHandler('bottomAlertVue', JSON.stringify(json), this.onDownloadCancel);
            
        },
        saveTaskFirstCallback(data){

        },
        loadTaskFirstCallback(data){
            if(data != "" && data != "N"){
                try{
                    var json = JSON.parse(data);
                    json[`lsid_${this.lsid}`] = "Y";
                    var saveJson = {
                        key: "isTaskCompleteFirst",
                        object: JSON.stringify(json),
                    };
                    this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
                }catch(err) {
                    console.log(err);
                }
            }else{
                    var json = {};
                    json[`lsid_${this.lsid}`] = "Y";
                    var saveJson = {
                        key: "isTaskCompleteFirst",
                        object: JSON.stringify(json),
                    };
                    this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( saveJson ), this.saveTaskFirstCallback);
            }
        },
        completeTask(){
            this.form = {};
            this.form["uid"] = this.$GlobalData.uid;
            this.form["lsid"] = this.lsid;
            this.apiName = 'page_word_preview_complete_task';
            oAC.postRecord(oAC.getApiParam(this)).then((res)=> {
                if(res.history_user_lesson_schedule){
                    if(res.history_user_lesson_schedule.first && res.history_user_lesson_schedule.first == "Y" && res.history_user_lesson_schedule.complete_5 && res.history_user_lesson_schedule.complete_5 == "Y"){
                        this.$JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "isTaskCompleteFirst"}), this.loadTaskFirstCallback);
                    }
                }
            }).catch((err)=> {
                oltConsole.log(err);
            });
        },
        getQuery(){
            var query = "";
            if(this.params && this.params != undefined){
                query = Object.keys(this.params)
                .map(key => {
                    return (
                    encodeURIComponent(key) + '=' + encodeURIComponent(this.params[key])
                    )
                })
                .join('&');
            }
            return query;
        },
        changeToWebCallback(data){},
        openTeacherExplainCallback(data){},
        openEssayReadCallback(data){},
        onTeacherExplain(){
            var query = this.getQuery();
            var vueBack = `WordPreview?lsid=${this.lsid}&backUrl=${this.backUrl}${query}`;
            this.$JsBridge.callHandler('openTeacherExplain',JSON.stringify({
                lsid: this.lsid,
                backUrl: vueBack,
                mode: "vue",
                type: "open",
            }), this.openTeacherExplainCallback);
        },
        setOnBack(){
            this.$JsBridge.callHandler('setOnback', '', this.onBack);
        },
        onBack(){
            if(this.native != null && this.native != undefined && this.native != ""){
                if(this.native.indexOf("essay_read") > -1){
                    this.$JsBridge.callHandler('openEssayRead',JSON.stringify({
                        backUrl: this.backUrl,
                        mode: "vue",
                        type: "back",
                    }), this.openEssayReadCallback);
                }else{
                    this.$router.push(
                    {
                        name : "TaskList",
                        params: {
                            lsid: this.lsid,
                        },
                    });
                }
            }else{
                if(this.backMode != null && this.backMode != undefined && this.backMode != ""){
                    if(this.backUrl != null && this.backUrl != undefined && this.backUrl != ""){
                        if(this.backMode == "vue"){
                            var name = "";
                            var params = {};
                            if(this.backUrl && this.backUrl != "" && this.backUrl != undefined && this.backUrl.indexOf("?") > -1){
                                name = this.backUrl.substring(0, this.backUrl.indexOf("?"));
                                var queryStr = this.backUrl.substring(this.backUrl.indexOf("?") + 1, this.backUrl.length);
                                if(queryStr != ""){
                                const urlSearchParams = new URLSearchParams(queryStr);   
                                    params = Object.fromEntries(urlSearchParams.entries()); 
                                }
                            }
                            this.$JsBridge.callHandler('log', `name ==> ${name}`, this.changeToWebCallback);
                            //this.$JsBridge.callHandler('log', `params ==> ${JSON.stringify(params)}`, this.changeToWebCallback);                            
                            this.$router.push(
                            {
                                name : name,
                                params: params,
                            });
                        }else{
                            var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
                            if(base64.test(this.backUrl)){
                                this.backUrl = atob(this.backUrl);
                            }
                            this.$JsBridge.callHandler('openWebView', this.backUrl, this.changeToWebCallback);
                        }
                    }else{
                        this.$router.push(
                        {
                            name : "TaskList",
                            params: {
                                lsid: this.lsid,
                            },
                        });
                    }
                }else{
                    var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
                    if(base64.test(this.backUrl)){
                        this.backUrl = atob(this.backUrl);
                    }
                    this.$JsBridge.callHandler('openWebView', this.backUrl, this.changeToWebCallback);
                }
            }
        },
        onReload(name, query){
            this.onStartup(query);
        },
        onStartup(query){
            this.$JsBridge.callHandler('log', `query ==> ${JSON.stringify(query)}`, this.changeToWebCallback);
            if(query && query != undefined && JSON.stringify(query)!='{}'){
                if(query.lsid && query.lsid != undefined){
                    this.lsid = query.lsid;
                }
                if(query.backUrl && query.backUrl != undefined){
                    this.backUrl = query.backUrl;
                }
                if(query.backMode && query.backMode != undefined){
                    this.backMode = query.backMode;
                }
                if(query.native && query.native != undefined){
                    this.native = query.native;
                }
                this.params = query;
            }else if(this.$route.params && this.$route.params != undefined && JSON.stringify(this.$route.params)!='{}'){
                if(this.$route.params.lsid && this.$route.params.lsid != undefined){
                    this.lsid = this.$route.params.lsid;
                }
                if(this.$route.params.backUrl && this.$route.params.backUrl != undefined){
                    this.backUrl = this.$route.params.backUrl;
                }
                if(this.$route.params.backMode && this.$route.params.backMode != undefined){
                    this.backMode = this.$route.params.backMode;
                }
                if(this.$route.params.native && this.$route.params.native != undefined){
                    this.native = this.$route.params.native;
                }
                this.params = this.$route.params;
            }else if(this.$route.query && this.$route.query != undefined && JSON.stringify(this.$route.query)!='{}'){
                if(this.$route.query.lsid && this.$route.query.lsid != undefined){
                    this.lsid = this.$route.query.lsid;
                }
                if(this.$route.query.backUrl && this.$route.query.backUrl != undefined){
                    this.backUrl = this.$route.query.backUrl;
                }
                if(this.$route.query.backMode && this.$route.query.backMode != undefined){
                    this.backMode = this.$route.query.backMode;
                }
                if(this.$route.query.native && this.$route.query.native != undefined){
                    this.native = this.$route.query.native;
                }
                this.params = this.$route.query;
            }
            if(this.native == ""){
                var base64 = /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{4}|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{2}==)$/;
                if(this.backUrl != null && this.backUrl != "" && base64.test(this.backUrl)){
                    this.backUrl = atob(this.backUrl);
                }
            }
            //this.$JsBridge.callHandler('log', `lsid ==> ${this.lsid}`, this.changeToWebCallback);
            this.$JsBridge.callHandler('log', `backUrl ==> ${this.backUrl}`, this.changeToWebCallback);
            this.$JsBridge.callHandler('log', `backMode ==> ${this.backMode}`, this.changeToWebCallback);
            if(this.$GlobalData.user && this.$GlobalData.user.rt){
                if(this.$GlobalData.user.rt.user && this.$GlobalData.user.rt.user.length > 0){
                    if(this.$GlobalData.user.rt.user[0] && this.$GlobalData.user.rt.user[0].ulid && this.$GlobalData.user.rt.user[0].ulid != undefined){
                        this.ulid = this.$GlobalData.user.rt.user[0].ulid;
                    }
                }
            }
            //this.$GlobalData.uid = 91;
            //this.lsid = 119;
            //this.ulid = this.$GlobalData.user_level_level4;
            this.isVisitor = this.ulid == "" || this.ulid == this.$GlobalData.user_level_level1;
            this.isMember = this.ulid == this.$GlobalData.user_level_level2;
            console.log(`isVisitor ==> ${this.isVisitor},  isMember ==> ${this.isMember}`);
            this.apiName = 'page_word_preview';
            oAC.getAllRecord(oAC.getApiParam(this), {uid:this.$GlobalData.uid, lsid:this.lsid}).then(
            res => 
                {
                if(res != null && res.lesson_word != null && res.lesson_word.length > 0){ 
                    this.lesson_word = res.lesson_word;
                    if(this.isVisitor || this.isMember){
                        if(this.lesson_word.length < 4){
                            this.wordMax = this.lesson_word.length;
                        }else{
                            this.wordMax = 4;
                        }
                    }else{
                        this.wordMax = this.lesson_word.length;
                    }
                    for(var i = 0; i < this.lesson_word.length; i++){
                        this.lesson_word[i].isPlay = "N";
                        if(this.lesson_word[i].audio_url_m3u8 && this.lesson_word[i].audio_url_m3u8 != undefined){
                            this.wordAudioList.push(this.lesson_word[i].audio_url_m3u8);
                            this.allAudioList.push(this.lesson_word[i].audio_url_m3u8);
                        }
                        if(this.lesson_word[i].lesson_word_sentence && this.lesson_word[i].lesson_word_sentence.length > 0){
                            for(var k = 0; k < this.lesson_word[i].lesson_word_sentence.length; k++){
                                this.lesson_word[i].lesson_word_sentence[k].isPlay = "N";
                                if(this.lesson_word[i].lesson_word_sentence[k].audio_url_m3u8 && this.lesson_word[i].lesson_word_sentence[k].audio_url_m3u8 != undefined){
                                    this.allAudioList.push(this.lesson_word[i].lesson_word_sentence[k].audio_url_m3u8);
                                }
                            }
                        }
                    }
                }
                }
            )
            .catch((err)=> {
                oltConsole.log(err);
            });
        },
        getIntroStatusCallback(res){
            if (res == "N") {
                this.isTeaching = true;
                this.$nextTick(() => {
                    $("html").css("overflow", "hidden");
                    stop();
                }); 
            }else{
                this.isTeaching = false;
                $("html").css("overflow", "visible");
                move();
            }
        },
        onTeaching(){
            var json = {
                key: "intro_word_preview",
                object: "Y",
            };
            this.$JsBridge.callHandler('saveLocalDataVue', JSON.stringify( json ), this.saveIntroStatusCallback);
        },
        saveIntroStatusCallback(){
            this.isTeaching = false;
            $("html").css("overflow", "visible");
            move();
        }
    },
    computed: {
    },
    mounted() {
        console.log('this is current player instance object', this.player);
        this.config = config;
        if(config && config.is_show_intro && config.is_show_intro=="Y"){
            this.$JsBridge.callHandler('loadLocalDataVue', JSON.stringify( {key: "intro_word_preview"} ), this.getIntroStatusCallback);
        }
    },
    created(){ 
        if (this.$route.query.uid != undefined) {
            this.$GlobalData.uid = this.$route.query.uid;
        }
        if (this.$route.query.lsid != undefined) {
            this.lsid = this.$route.query.lsid;
        }
        if (this.$JsBridge.isAndroid) {
            this.setOnBack();
        }
        if(this.$route.query && this.$route.query != null && this.$route.query != undefined && JSON.stringify(this.$route.query)!='{}'){
            this.onStartup(this.$route.query);
        }else if(this.$route.params && this.$route.params != null && this.$route.params != undefined && JSON.stringify(this.$route.params)!='{}'){
            this.onStartup(this.$route.params);
        }else{
            this.onStartup(null);
        }
    },
    beforeDestroy() {
        this.isTeaching = false;
        if (this.$refs.allPlaye.player) {
            this.$refs.allPlaye.player.dispose()
        }
        if (this.$refs.wordPlayer.player) {
            this.$refs.wordPlayer.player.dispose()
        }
        if (this.$refs.voicPlayer.player) {
            this.$refs.voicPlayer.player.dispose()
        }
    }
};

var mo=function(e){e.preventDefault();};
function stop(){
  document.body.style.overflow='hidden';        
  document.addEventListener("touchmove",mo,false);//禁止滑動
}
/***取消滑動限制***/
function move(){
  document.body.style.overflow='';
  document.removeEventListener("touchmove",mo,false);        
}
</script>
<style>
@import '../../../../public/assets/css/admin-customize.css';

.vue-slider-process{
    background-image: linear-gradient( 310deg, #00ccad 0%, #00ccad 100%);
    border-radius: 3px !important;
}
.vue-slider-dot{
    border-radius: 50% !important;
    background-color: #fff !important;
    box-shadow: 0 2px 4px 0 rgb(0 208 172 / 30%) !important;
    height: 21px !important;
    width: 21px !important;
    cursor: pointer !important;
    outline: none !important;
    right: -10px !important;
    border: 7px solid #00ccad !important;
    transition: left 0s ease 0s !important;
}
.vue-slider-dot-handle{
    border: 0px !important; 
}
.vue-slider-process{
    transition-duration: 0s !important;
}
.dic_gcolor{
    color: #00b99d;
}
</style>
